import * as i0 from '@angular/core';
import { Injectable, Inject, EventEmitter, Component, Input, Output, NgModule } from '@angular/core';
import { Observable } from 'rxjs';
import { take, shareReplay } from 'rxjs/operators';
import { DOCUMENT, CommonModule } from '@angular/common';
class ScriptService {
  constructor(_document) {
    this._document = _document;
    this.scriptsLoaders = {};
  }
  _loadScript(url, attributes, targetEl = 'head') {
    return new Observable(observer => {
      const script = this._document.createElement('script');
      if (attributes) {
        for (const key in attributes) {
          if (attributes.hasOwnProperty(key)) {
            script.setAttribute(key, attributes[key]);
          }
        }
      }
      script.onload = event => {
        observer.next(event);
        observer.complete();
      };
      script.onerror = err => {
        observer.error(err);
      };
      script.src = url;
      const targetElement = typeof targetEl === 'string' ? this._document.querySelector(targetEl) : targetEl;
      targetElement.appendChild(script);
    });
  }
  /**
   * Injects script from given url to target place in DOM
   * This method loads script from same url once.
   *
   * @param url Url of the external script to be loaded
   * @param attributes Attribute list to be added to the script element
   * @param targetEl Target element for the placing script tag. It can be a selector or a element reference
   */
  loadScript(url, attributes, targetEl = 'head') {
    return this.scriptsLoaders[url] = this.scriptsLoaders[url] || this._loadScript(url, attributes, targetEl).pipe(take(1), shareReplay(1));
  }
  /**
   * Injects script from given url to target place in DOM
   * If you call this method for same url multiple times, it will inject same code to document multiple times.
   *
   * @param url Url of the external script to be loaded
   * @param attributes Attribute list to be added to the script element
   * @param targetEl Target element for the placing script tag. It can be a selector or a element reference
   */
  runScript(url, attributes, targetEl = 'head') {
    return this._loadScript(url, attributes, targetEl);
  }
}
ScriptService.ɵfac = function ScriptService_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ScriptService)(i0.ɵɵinject(DOCUMENT));
};
ScriptService.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: ScriptService,
  factory: ScriptService.ɵfac,
  providedIn: 'root'
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ScriptService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [DOCUMENT]
      }]
    }];
  }, null);
})();

/* eslint-disable @angular-eslint/no-output-native */
class ScriptComponent {
  constructor(scriptService, element) {
    this.scriptService = scriptService;
    this.element = element;
    this.attributes = {};
    this.load = new EventEmitter();
    this.error = new EventEmitter();
  }
  ngOnInit() {
    this.scriptService.loadScript(this.src, this.attributes, this.element.nativeElement).subscribe(event => {
      this.load.emit(event);
    }, error => {
      this.error.emit(error);
    });
  }
}
ScriptComponent.ɵfac = function ScriptComponent_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ScriptComponent)(i0.ɵɵdirectiveInject(ScriptService), i0.ɵɵdirectiveInject(i0.ElementRef));
};
ScriptComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ScriptComponent,
  selectors: [["ngx-script"]],
  inputs: {
    src: "src",
    attributes: "attributes"
  },
  outputs: {
    load: "load",
    error: "error"
  },
  decls: 0,
  vars: 0,
  template: function ScriptComponent_Template(rf, ctx) {},
  encapsulation: 2
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ScriptComponent, [{
    type: Component,
    args: [{
      selector: 'ngx-script',
      template: '',
      styles: []
    }]
  }], function () {
    return [{
      type: ScriptService
    }, {
      type: i0.ElementRef
    }];
  }, {
    src: [{
      type: Input
    }],
    attributes: [{
      type: Input
    }],
    load: [{
      type: Output
    }],
    error: [{
      type: Output
    }]
  });
})();
class ScriptLoaderModule {}
ScriptLoaderModule.ɵfac = function ScriptLoaderModule_Factory(__ngFactoryType__) {
  return new (__ngFactoryType__ || ScriptLoaderModule)();
};
ScriptLoaderModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ScriptLoaderModule
});
ScriptLoaderModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ScriptLoaderModule, [{
    type: NgModule,
    args: [{
      declarations: [ScriptComponent],
      imports: [CommonModule],
      exports: [ScriptComponent]
    }]
  }], null, null);
})();

/*
 * Public API Surface of ngx-script-loader
 */

/**
 * Generated bundle index. Do not edit.
 */

export { ScriptComponent, ScriptLoaderModule, ScriptService };
